<template>
  <!-- 服务条款 -->
  <div class="bind-card">
    <!-- 导航条 -->
    <HeaderBar
      :currentName="_t18(`sidebar_termsService`)"
      :cuttentRight="cuttentRight"
      :border_bottom="true"
    ></HeaderBar>
    <!--内容-->
    <div class="itemDetailObj" v-html="currentHtml"></div>
    <Nodata v-if="loading"></Nodata>
  </div>
</template>
<script setup>
import { _t18 } from '@/utils/public'
import { rulesList } from '@/api/common/index'
import {ref} from "vue";
import {closeToast, showLoadingToast} from "vant";
const currentHtml = ref(null)
const loading = ref(false)

onMounted(async () => {
  showLoadingToast({
    message: _t18(`loading`),
    forbidClick: true,
    duration: 0,
    loadingType: 'spinner'
  })
  try {
    const res = await rulesList('TERMS_CLAUSE')
    closeToast()
    if (res.code === 200) {
      currentHtml.value = res.data[0].content
      if(!currentHtml.value){
        loading.value = true;
      }
    }
  } catch (error) {
    loading.value = true;
    closeToast()
  }
})
</script>
<style>
.itemDetailObj {
  font-size: 12px;
  padding: 10px 15px;
  color: var(--ex-default-font-color);
  line-height: 24px;
  word-wrap: break-word;
}
.itemDetailObj img {
  width: 100%;
}
</style>
